import React from "react";
import {Link} from "react-router-dom";
import BlogCategory from '../../components/Blog/BlogCategory'
import BlogTags from '../../components/Blog/BlogTags'
import {Helmet} from "react-helmet";
import SharePage from "../../components/Tools/SharePage";
import LatestBlog from "../../components/Blog/LatestBlog";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Comment from "../../components/Blog/Comment";
import CommentSkeleton from "../../components/Blog/CommentSkeleton";

class BlogDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: {
                id: 0,
                title: '',
                date: '',
                content: '',
                featured: '',
                categories: [{}],
                tags: [{}]
            },
            comments: [{}],
            isLoaded: false,
        };
    }

    componentDidMount() {
        let slug = window.location.pathname.split('/')[2]
        fetch('https://api.pionatec.com/?rest_route=/wp/v2/posts&_embed&slug=' + slug)
            .then(res => res.json())
            .then(result => {
                let el = result[0];

                this.setState({
                    items: {
                        id: el.id,
                        title: el.title.rendered,
                        date: el.date,
                        content: el.content.rendered,
                        featured: el._embedded["wp:featuredmedia"][0].source_url,
                        categories: el._embedded["wp:term"][0],
                        tags: el._embedded["wp:term"][1]
                    },

                });
                fetch('https://api.pionatec.com/wp-json/wp/v2/comments?post=' + el.id)
                    .then(res => res.json())
                    .then(result => {
                        this.setState({
                            comments: result,
                            isLoaded: true,
                        });
                    });
            });


    }

    render() {
        let states = this.state;

        const sendComment = () => {
            let postId = this.state.items.id
            let cmContent = document.getElementsByName('commentContent')[0].value;
            let cmName = document.getElementsByName('authorName')[0].value;
            let cmEmail = document.getElementsByName('authorEmail')[0].value;


            fetch("https://api.pionatec.com/wp-json/wp/v2/comments?post=" + postId + "&content=" + cmContent + "&author_name=" + cmName + "&author_email=" + cmEmail + "", {
                method: 'POST'
            })
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    alert('Your Comment Created!')
                })
                .catch(error => console.log('error', error));
        }

        return (
            <>
                <Helmet>
                    <title>PIONATEC - {states.items.title}</title>
                </Helmet>
                <section className="blog-right-side-bar">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div
                                className="col-12 col-lg-8 right-sidebar md-margin-60px-bottom sm-margin-40px-bottom">
                                <div className="row">
                                    <div
                                        className="col-12 blog-details-text last-paragraph-no-margin margin-6-rem-bottom">
                                        <ul className="list-unstyled margin-2-rem-bottom">
                                            <li className="d-inline-block align-middle margin-25px-right"><i
                                                className="feather icon-feather-calendar text-fast-blue margin-10px-right"/>
                                                <span>
                                                    {
                                                        (states.isLoaded === true) ?
                                                            new Date(states.items.date).toLocaleDateString('en-US', {
                                                                year: 'numeric',
                                                                month: 'long',
                                                                day: 'numeric'
                                                            }) :
                                                            <Skeleton width={'100px'}/>
                                                    }

                                                </span>
                                            </li>
                                            <li className="d-inline-block align-middle margin-25px-right"><i
                                                className="feather icon-feather-folder text-fast-blue margin-10px-right"/>
                                                {
                                                    (states.isLoaded === true) ?
                                                        states.items.categories.map((cats, i) =>
                                                            <Link to={"/blog/category/" + cats.slug}
                                                                  key={i}>{cats.name}</Link>
                                                        ) :
                                                        <Skeleton width={'100px'}/>
                                                }
                                            </li>

                                        </ul>
                                        <h5 className="alt-font font-weight-500 text-extra-dark-gray margin-4-half-rem-bottom">

                                            {(states.isLoaded === true) ?
                                                states.items.title :
                                                <Skeleton width={'80%'}/>
                                            }
                                        </h5>
                                        {
                                            (states.isLoaded === true) ?
                                                <img
                                                    src={(states.items.featured != null) ? states.items.featured : ''}
                                                    alt=""
                                                    className="w-100 border-radius-6px margin-4-half-rem-bottom"/> :
                                                <Skeleton width={'100%'} height={'400px'}/>
                                        }

                                        {
                                            (states.isLoaded === true) ?
                                                <div dangerouslySetInnerHTML={{__html: states.items.content}}/> :
                                                <Skeleton count={10}/>
                                        }


                                    </div>
                                    <div
                                        className="col-12 d-flex flex-wrap align-items-center padding-15px-tb mx-auto margin-20px-bottom wow animate__fadeIn">
                                        <div
                                            className="col-12 col-md-9 text-center text-md-start sm-margin-10px-bottom px-0">
                                            <div className="tag-cloud">
                                                {
                                                    states.items.tags.map((tag, i) =>
                                                        <Link to={"/blog/tag/" + tag.slug} key={i}>{tag.name}</Link>
                                                    )
                                                }

                                            </div>
                                        </div>
                                        {/*<div className="col-12 col-md-3 text-center text-md-end px-0">*/}
                                        {/*    <a className="likes-count text-uppercase text-extra-dark-gray font-weight-500"*/}
                                        {/*       href="#"><i*/}
                                        {/*        className="far fa-heart"></i><span>05 Likes</span></a>*/}
                                        {/*</div>*/}
                                    </div>

                                    <SharePage/>
                                </div>
                            </div>
                            <aside
                                className="col-12 col-xl-3 offset-xl-1 col-lg-4 col-md-7 blog-sidebar lg-padding-4-rem-left md-padding-15px-left">
                                <BlogCategory/>

                                <BlogTags/>

                            </aside>

                        </div>
                    </div>
                </section>

                <section className="bg-light-gray">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div
                                className="col-12 col-lg-5 col-md-6 text-center margin-5-rem-bottom wow animate__fadeIn">
                                <span className="alt-font font-weight-500 text-uppercase d-inline-block">You may also like</span>
                                <h5 className="alt-font font-weight-500 text-extra-dark-gray letter-spacing-minus-1px">
                                    Latest posts
                                </h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 blog-content">
                                <LatestBlog/>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Comment*/}
                {
                    (states.comments.length > 0) ?
                        <section>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-md-6 text-center margin-5-rem-bottom wow animate__fadeIn">
                                        <h6 className="alt-font text-extra-dark-gray font-weight-500">{states.comments.length} Comments</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-9 mx-auto wow animate__fadeIn">
                                        <ul className="blog-comment">
                                            {
                                                (states.isLoaded === true) ?
                                                states.comments.map((item, i) =>
                                                    <li key={i}>
                                                        <Comment elements={item}/>
                                                    </li>
                                                ) :
                                                    Array.apply(0, Array(1)).map((item, i) =>
                                                        <li key={i}>
                                                            <CommentSkeleton/>
                                                        </li>
                                                    )
                                            }

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section> : ''
                }

                <section id="comments">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-9 margin-4-rem-bottom wow animate__fadeIn">
                                <h6 className="alt-font text-extra-dark-gray font-weight-500 margin-5px-bottom">Write a
                                    comments</h6>
                                <div className="margin-5px-bottom">Your email address will not be published. Required
                                    fields are marked
                                    <span className="text-radical-red">*</span></div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-9 wow animate__fadeIn">
                                <form>
                                    <div className="row align-items-center">
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <label className="margin-15px-bottom" htmlFor="basic-name">Your name <span
                                                className="text-radical-red">*</span></label>
                                            <input id="basic-name"
                                                   className="medium-input border-radius-4px bg-white margin-30px-bottom required"
                                                   type="text" name="authorName" placeholder="Enter your name"/>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <label className="margin-15px-bottom" htmlFor="basic-email">Your email
                                                address <span
                                                    className="text-radical-red">*</span></label>
                                            <input id="basic-email"
                                                   className="medium-input border-radius-4px bg-white margin-30px-bottom required"
                                                   type="email" name="authorEmail" placeholder="Enter your email"/>
                                        </div>
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <div className="margin-15px-bottom">Your comment</div>
                                            <textarea
                                                className="medium-textarea border-radius-4px bg-white h-120px margin-2-half-rem-bottom"
                                                rows="6" name="commentContent" placeholder="Enter your comment"/>
                                        </div>
                                        <div className="col-12 sm-margin-20px-bottom">
                                            <input type="hidden" name="redirect" value=""/>
                                            <input
                                                className="btn btn-medium btn-dark-gray mb-0 btn-round-edge-small submit"
                                                type="submit" name="submit" onClick={sendComment} value="Post Comment"/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default BlogDetail;