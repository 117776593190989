import React, {useContext, useEffect} from 'react';
import {Link} from "react-router-dom";
import {useState} from "react";
import Services from "../../components/Home/Services";
import ServiceCard from "../../components/Services/ServiceCard";
import ServiceCardSkeleton from "../../components/Services/ServiceCardSkeleton";
import {ServiceDataContext} from "../../context/ServiceDataContext";

const ServicesList = () => {
    const {serviceItems, setServiceItems} = useContext(ServiceDataContext);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (serviceItems.length > 0) {
            setIsLoaded(true)
        }
    }, [serviceItems])

    return (
        <>
            <section
                className="wow animate__fadeIn bg-light-gray padding-40px-tb sm-padding-30px-tb page-title-small"
                style={{visibility: "visible", animationName: "fadeIn"}}>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-8 col-lg-6 text-center text-lg-start">
                            <h1 className="alt-font text-extra-dark-gray font-weight-500 no-margin-bottom d-inline-block">
                                Services
                            </h1>
                            {/*<span className="alt-font text-medium d-block d-md-inline-block sm-margin-5px-top">Short tagline goes here</span>*/}
                        </div>
                        <div
                            className="col-xl-4 col-lg-6 text-center text-lg-end breadcrumb justify-content-center justify-content-lg-end text-small alt-font md-margin-15px-top">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li className="text-primary">Services</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </section>
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 blog-content">

                            {(isLoaded === true) ?
                                <div className="blog-list blog-side-image">
                                    {serviceItems.map((item, i) =>
                                        <ServiceCard key={i} elements={item}/>
                                    )}

                                </div>
                                :
                                <div className="blog-list blog-side-image">
                                    {
                                        Array.apply(0, Array(6)).map((item, i) =>
                                            <ServiceCardSkeleton key={i}/>
                                        )
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServicesList;