import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Autoplay, Keyboard} from "swiper";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const PartnersLogoSkeleton = () => {
    return (
        <Swiper
            modules={[Navigation, Autoplay, Keyboard]}
            className={'swiper-container slider-zoom-slide slider-zoom-slide-dark black-move p-0'}
            spaceBetween={0}
            slidesPerView={4}
            loop={true}
            centeredSlides={true}
            initialSlide={2}
            speed={10000}
            autoplay={{
                delay: 1,
                disableOnInteraction: false
            }}
            breakpoints={{
                992: {
                    slidesPerView: 7
                },
                767: {
                    slidesPerView: 4,
                }
            }}
            keyboard={{enabled: true, onlyInViewport: true}}
            navigation={{
                prevEl: ".swiper-button-previous-nav",
                nextEl: ".swiper-button-next-nav",
            }}
        >
            {
                Array.apply(0, Array(6)).map((item, i) =>
                    <SwiperSlide key={i}>
                        <Skeleton height={'80px'} width={'100px'}/>
                    </SwiperSlide>
                )
            }
        </Swiper>
    );
};

export default PartnersLogoSkeleton;