import React from 'react';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

const NotFound = () => {
    return (
        <>
            <Helmet>
                <Helmet>
                    <title>PIONATEC - 404</title>
                </Helmet>
            </Helmet>
            <section className="p-0 cover-background wow animate__fadeIn"
                     style={{visibility: "visible", animationName: "fadeIn"}}>
                <div className="container">
                    <div className="row align-items-stretch justify-content-center full-screen"
                         style={{height: "648px"}}>
                        <div
                            className="col-12 col-xl-6 col-lg-7 col-md-8 text-center d-flex align-items-center justify-content-center flex-column">
                            <h6 className="alt-font text-fast-blue font-weight-600 letter-spacing-minus-1px margin-10px-bottom text-uppercase">Ooops!</h6>
                            <h1 className="alt-font text-extra-big font-weight-700 letter-spacing-minus-5px text-extra-dark-gray margin-6-rem-bottom md-margin-4-rem-bottom">404</h1>
                            <span className="alt-font font-weight-500 text-extra-dark-gray d-block margin-20px-bottom">This page could not be found!</span>
                            <Link to="/" className="btn btn-large btn-gradient-sky-blue-pink">Back to homepage</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default NotFound;