import React from 'react';

const Comment = (props) => {
    let element = props.elements
    let avatar = typeof element.author_avatar_urls != 'undefined' ? element.author_avatar_urls["96"] : '';
    let content = typeof element.content != 'undefined' ? element.content.rendered : '';
    console.log(element)
    return (
        <div
            className="d-block d-md-flex w-100 align-items-center align-items-md-start ">
            <div className="w-75px sm-w-50px sm-margin-10px-bottom">
                <img src={avatar}
                     className="rounded-circle w-95 sm-w-100"
                     alt=""/>
            </div>
            <div
                className="w-100 padding-25px-left last-paragraph-no-margin sm-no-padding-left">
                <p
                    className="text-extra-dark-gray text-fast-blue-hover alt-font font-weight-500 text-medium">
                    {element.author_name}
                </p>
                {/*<a href="#comments"*/}
                {/*   className="btn-reply text-medium-gray text-uppercase section-link">Reply</a>*/}
                <div className="text-medium text-medium-gray margin-15px-bottom">
                    {
                        new Date(element.date).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric'
                        })
                    }
                </div>
                <p className="w-85">
                    <div dangerouslySetInnerHTML={{__html: content}}/>
                </p>
            </div>
        </div>
    );
};

export default Comment;