import {createContext, useState} from 'react';
export const ServiceDataContext = createContext(undefined);

export const ServiceDataProvider = (props) => {
    const [serviceItems, setServiceItems] = useState([])
    return (
        <ServiceDataContext.Provider value={{serviceItems, setServiceItems}}>
            {props.children}
        </ServiceDataContext.Provider>
    );
};