import React from 'react'
import {Helmet} from "react-helmet";

import 'swiper/swiper.scss'

import Slider from "../../components/Home/Slider";
import Blog from "../../components/Home/Blog";
import Partners from "../../components/Home/Partners";
import Services from "../../components/Home/Services";
import About from "../../components/Home/About";
import Banner from "../../components/Home/banner";
import SwiperCore, {Autoplay, Navigation, Pagination} from "swiper";
// import Counter from "../../components/Pages/Home/counter";

const IndexPage = () => {
    SwiperCore.use([Autoplay, Navigation, Pagination])

    return (
        <>
            <Helmet>
                <title>PIONATEC</title>
            </Helmet>
            <Slider/>
            <About/>
            <Services/>
            {/*<Counter/>*/}
            <Banner/>
            {/*<Blog/>*/}
            {/*<Partners/>*/}

        </>
    );

};

export default IndexPage;