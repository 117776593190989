import React from 'react';
import {Link} from "react-router-dom";

class BlogCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [], isLoaded: false,
        };
    }

    componentDidMount() {
        // fetch('https://api.pionatec.com/?rest_route=/wp/v2/posts&_embed&per_page=6&page=1')
        fetch('https://api.pionatec.com/wp-json/wp/v2/categories')
            .then(res => res.json())
            .then(result => {
                this.setState({
                    isLoaded: true, items: result
                });
            });
    }

    render() {
        const {items} = this.state;
        const changeHandler =(e)=>{
            console.log(e.target.getAttribute('slug'));
        }
        return (

            <>
                <div className="margin-5-rem-bottom xs-margin-35px-bottom wow animate__fadeIn">
                        <span
                            className="alt-font font-weight-500 text-large text-extra-dark-gray d-block margin-35px-bottom">Categories</span>
                    <ul className="list-style-07 list-unstyled">
                        {
                            items.map((item, i) =>
                                <li onClick={changeHandler} key={i} slug={item.slug}>{item.name}<span className="item-qty">{item.count}</span></li>
                            )}

                    </ul>
                </div>

            </>
        );
    }

}
export default BlogCategory;
