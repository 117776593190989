import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {Link} from "react-router-dom";

const ServiceCardSkeleton = () => {
    function hightCalculation() {
        if (window.innerWidth > 991) {
            return (1320 / 3 - 30) * 2 / 3 + 'px'
        }
        else if (window.innerWidth < 992 && window.innerWidth > 425) {
            return 720 / 2 - 30 + 'px'
        }
        else {
            return window.innerWidth - 30 + 'px'
        }
    }
    return (
            <div className="blog-post bg-white box-shadow-medium margin-30px-bottom wow animate__fadeIn"
                 style={{visibility: "visible",animationName: "fadeIn"}}>
                <div className="d-flex flex-column flex-md-row align-items-center">
                    <div className="blog-post-image sm-margin-25px-bottom">
                        <Link to="blog-post-layout-01.html" title="">
                            <Skeleton height={hightCalculation()} />
                        </Link>
                    </div>
                    <div
                        className="post-details padding-4-half-rem-lr md-padding-2-half-rem-lr sm-no-padding">

                        <Link to="blog-post-layout-01.html"
                              className="alt-font font-weight-500 text-extra-large text-extra-dark-gray d-block margin-20px-bottom sm-margin-10px-bottom">  <Skeleton width="30%" /></Link>
                        <div className="margin-seventeen-bottom sm-margin-25px-bottom">  <Skeleton width="60%" /></div>
                        <div
                            className="alt-font text-extra-small text-uppercase d-flex align-items-center sm-margin-10px-bottom">
                            {/*<img className="avtar-image" src="images/avtar10.jpg" alt=""*/}
                            {/*     data-no-retina=""/>*/}

                        </div>
                    </div>
                </div>
            </div>
    );
};

export default ServiceCardSkeleton;