import {createContext, useState} from 'react';
export const SliderDataContext = createContext(undefined);

export const SliderDataProvider = (props) => {
    const [sliderItems, setSliderItems] = useState([])
    return (
        <SliderDataContext.Provider value={{sliderItems, setSliderItems}}>
            {props.children}
        </SliderDataContext.Provider>
    );
};